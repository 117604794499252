
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from 'react';
import useTranslationWithFallback from 'hooks/use-translation-with-fallback';

// Utils
import bemify from 'utils/bemify';

// Components
import Button from 'components/button';

// Styles
import stylesModule from 'styles/pages/404.module.scss';


const styles = bemify(stylesModule);

const NotFoundPage = () => {
  const { t } = useTranslationWithFallback('404');

  return (
    <section className={`container ${styles.notFoundPage__content}`}>
      <h2 className={styles.notFoundPage__title}>
        {t('title')}
      </h2>
      <h3 className={styles.notFoundPage__description}>
        {t('description')}
      </h3>
      <Button
        href="/"
        icon="arrow-left"
        iconPosition="left"
        noBackground
        paddingSize="none"
        text={t('button_text')}
      />
    </section>
  );
};

export default NotFoundPage;


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  